import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useRef,
} from "react";
import { Effect, fromPromiseFn } from "../utils/effect";

class JoinEvt {
  readonly type = "JOIN";
  constructor(public offer: any, public iceCandidates: any) {}
}

class JoinCompleteEvt {
  readonly type = "JOIN_COMPLETE";
  constructor(public answer: any) {}
}

class IceCandidateEvt {
  readonly type = "ICE_CANDIDATE";
  constructor(public candidate: any) {}
}

class ConnectionOpenEvt {
  readonly type = "CONNECTION_OPEN";
}

class ConnectionClosedEvt {
  readonly type = "CONNECTION_CLOSED";
}

/**
 * Send data through the established connection
 */
class SendEvt {
  readonly type = "SEND";
  constructor(public data: any) {}
}

/**
 * Send data through the established connection
 */
class SendCompleteEvt {
  readonly type = "SEND_COMPLETE";
}

/**
 * Receive data through the connection
 */
class ReceiveEvt {
  readonly type = "SEND";
  constructor(public data: any) {}
}

class CloseEvt {
  readonly type = "CLOSE";
}

class CloseCompleteEvt {
  readonly type = "CLOSE_COMPLETE";
}

type Evt =
  | JoinEvt
  | JoinCompleteEvt
  | IceCandidateEvt
  | ConnectionOpenEvt
  | ConnectionClosedEvt
  | SendEvt
  | SendCompleteEvt
  | ReceiveEvt
  | CloseEvt
  | CloseCompleteEvt;

interface RoomState {
  status:
    | "joining"
    | "waitingForHost"
    | "connected"
    | "sending"
    | "closing"
    | "closed";
  roomId?: string;
  outgoing?: any;
  answer?: any;
  iceCandidates: any[];
}

const roomReducer = (state: RoomState, evt: Evt): RoomState => {
  // Global events
  switch (evt.type) {
    case "CONNECTION_OPEN":
      console.log("open ------");
      return { ...state, status: "connected" };
    case "CONNECTION_CLOSED":
      return { ...state, status: "joining" };
    case "CLOSE":
      return { ...state, status: "closing" };
    case "CLOSE_COMPLETE":
      return { ...state, status: "closed" };
    case "ICE_CANDIDATE":
      return {
        ...state,
        iceCandidates: [...state.iceCandidates, evt.candidate],
      };
  }

  switch (state.status) {
    case "joining":
      if (evt.type === "JOIN_COMPLETE") {
        return {
          ...state,
          status: "waitingForHost",
          answer: evt.answer,
        };
      }
      return state;
    case "connected":
      switch (evt.type) {
        case "SEND_COMPLETE":
          return { ...state, status: "connected", outgoing: null };
        default:
          return state;
      }
    // Global evts
    default:
      // TODO probably shouldn't be global
      return state;
  }
};

interface RoomProps {
  onReceive: (data: any) => void;
  onIceCandidate: (cand: any) => void;
  offer: any;
  config: any;
}

/**
 * Setup webrtc in a guest role
 */
export const useGuestWebRTCConn = ({
  onReceive,
  offer,
  onIceCandidate,
  config,
}: RoomProps) => {
  // const [connectionStatus, dispatchConnectionStatus] = useReducer(
  //   (curr: "open" | "closed", evt: "OPEN" | "CLOSE") => {
  //     switch (curr) {
  //       case "closed":
  //         switch (evt) {
  //           case "OPEN":
  //             return "open";
  //           default:
  //             return curr;
  //         }
  //       case "open":
  //         switch (evt) {
  //           case "CLOSE":
  //             return "closed";
  //           default:
  //             return curr;
  //         }
  //       default:
  //         return curr;
  //     }
  //   },
  //   "closed"
  // );

  const [state, dispatch] = useReducer(roomReducer, {
    status: "joining",
    iceCandidates: [],
  });

  // Setup rtc stuff
  const peerConnection = useMemo(
    () => new RTCPeerConnection({ iceServers: config }),
    [config]
  );

  // Construct the answer
  const [answer, setAnswer] = useState(null);

  useEffect(
    () =>
      Effect.of(offer)
        .filter((offer) => !!offer)
        .chain((offer: any) =>
          fromPromiseFn(async () => {
            await peerConnection.setRemoteDescription(
              new RTCSessionDescription(offer)
            );
            const answer = await peerConnection.createAnswer();
            console.log("Created answer:", answer);
            await peerConnection.setLocalDescription(answer);
            return {
              answer: {
                type: answer.type,
                sdp: answer.sdp,
              },
            };
          })
        )
        .subscribe((a: any) => setAnswer(a)),
    [offer, peerConnection]
  );

  useEffect(() => {
    const handle = (event: RTCPeerConnectionIceEvent) => {
      if (!event.candidate) {
        console.log("Got final candidate!");
        return;
      }
      onIceCandidate(event.candidate.toJSON());
    };

    peerConnection.addEventListener("icecandidate", handle);
    return () => {
      peerConnection.removeEventListener("icecandidate", handle);
    };
  }, [dispatch, peerConnection, onIceCandidate]);

  // Setup the send channel
  const [sendChannel, setSendChannel] = useState<any>();
  useEffect(() => {
    const handleDataChannel = (event: RTCDataChannelEvent) => {
      setSendChannel(event.channel);
    };
    peerConnection.addEventListener("datachannel", handleDataChannel);
    return () => {
      peerConnection.removeEventListener("datachannel", handleDataChannel);
    };
  }, [peerConnection]);

  // Data channel connection status
  useEffect(() => {
    if (!sendChannel) {
      return;
    }
    const handleStateChange = () => {
      if (sendChannel.readyState === "open") {
        dispatch(new ConnectionOpenEvt());
      } else {
        dispatch(new ConnectionClosedEvt());
      }
    };

    handleStateChange();

    sendChannel.addEventListener("open", handleStateChange);
    sendChannel.addEventListener("close", handleStateChange);

    return () => {
      sendChannel.removeEventListener("open", handleStateChange);
      sendChannel.removeEventListener("close", handleStateChange);
    };
  }, [sendChannel]);

  // Receive messages
  useEffect(() => {
    if (!sendChannel) {
      return;
    }

    // The message receiver
    const handleMsgReceive = (event: MessageEvent) => {
      const msg = event.data;
      onReceive(JSON.parse(msg));
      console.log(msg, "msg-------------------");
    };

    sendChannel.addEventListener("message", handleMsgReceive);

    return () => {
      sendChannel.removeEventListener("message", handleMsgReceive);
    };
  }, [sendChannel, onReceive]);

  const send = useCallback(
    (msg: any) => {
      if (!sendChannel) {
        return;
      }
      console.log({ msg }, "send");
      sendChannel.send(JSON.stringify(msg));
    },
    [sendChannel]
  );

  const close = useCallback(() => {
    sendChannel && sendChannel.close();
  }, [sendChannel]);

  const candsToBeProcessed = useRef<any[]>([]);

  useEffect(() => {
    if (answer) {
      candsToBeProcessed.current.forEach((cand) => {
        peerConnection.addIceCandidate(new RTCIceCandidate(cand));
      });
      candsToBeProcessed.current = [];
    }
  }, [answer, peerConnection]);

  return {
    answer,
    status: state.status,
    send,
    close,
    addRemoteCandidate: (cand: any) => {
      if (answer) {
        console.log(cand, "guest remote cand");
        peerConnection.addIceCandidate(new RTCIceCandidate(cand));
      } else {
        candsToBeProcessed.current = [...candsToBeProcessed.current, cand];
      }
    },
  };
};
