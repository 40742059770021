import * as React from "react";

export { ReactComponent as Logo } from "./assets/logo.svg";
export { ReactComponent as Player1Piece } from "./assets/player-1-piece.svg";
export { ReactComponent as Player2Piece } from "./assets/player-2-piece.svg";
export { ReactComponent as CrownIcon } from "./assets/crown-icon.svg";

export const ThumbsUpIcon = ({ ...props }) => {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        d="M7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3m7-2V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a1.999 1.999 0 00-2-2.3H14z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
