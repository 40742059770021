// https://stackblitz.com/edit/typescript-im9hxp

// TODO could be source, sink and transformer maybe

type TeardownFn = () => void;

export class Effect<T> {
  private subscriber: any;
  private status: "pending" | "subscribed" | "closed" = "pending";

  static of(v: any) {
    return new Effect((o) => o.next(v));
  }

  constructor(private subscriberFn: (o: any) => void | TeardownFn) {}

  next(v: T) {
    if (this.status === "subscribed") {
      // todo wrap in catch
      try {
        this.subscriber.next(v);
      } catch (e) {
        this.subscriber.error(e);
      }
    }
  }

  chain<U>(fn: (v: T) => Effect<U>): Effect<U> {
    return new Effect((o) => {
      let innerTeardown: TeardownFn;

      const error = (e: any) => o.error(e);
      const complete = () => o.complete();

      const next = (v: any) => {
        const inner = fn(v);
        innerTeardown = inner.subscribe(
          (innerValue) => o.next(innerValue),
          error,
          complete
        );
      };

      const teardown = this.subscribe(next, error, complete);

      // Returning to pass the teardown on
      return () => {
        innerTeardown && innerTeardown();
        teardown();
      };
    });
  }

  map(fn: (v: any) => any) {
    return new Effect((o) => {
      // Here we subscribe the newly created mapping observer to "this"
      return this.subscribe(
        (v: any) => o.next(fn(v)),
        (e: any) => o.error(e),
        () => o.complete()
      );
    });
  }

  filter(fn: (v: any) => any) {
    return new Effect((o) => {
      return this.subscribe(
        (v: any) => fn(v) && o.next(v),
        (e: any) => o.error(e),
        () => o.complete()
      );
    });
  }

  subscribe(next = (v: T) => {}, error = (e: any) => {}, complete = () => {}) {
    this.status = "subscribed";

    this.subscriber = {
      next,
      error,
      complete,
    };

    const teardown = this.subscriberFn(this.subscriber);

    return () => {
      this.status = "closed";
      teardown && teardown();
    };
  }
}

type EventMap<T> = T extends Window
  ? WindowEventMap
  : T extends Document
  ? DocumentEventMap
  : { [key: string]: Event };

// TODO this should allow supplying the cb function
export const fromEventListener = <
  T extends EventTarget,
  K extends keyof EventMap<T> & string
>(
  target: T,
  type: K,
  options?: AddEventListenerOptions
) =>
  new Effect((o) => {
    target.addEventListener(type, o.next as EventListener, options);
    return () => {
      target.removeEventListener(type, o.next as EventListener, options);
    };
  });

export const fromPromise = (prom: any) =>
  new Effect((o) => {
    let isCurrent = true;
    prom
      .then((v: any) => {
        if (isCurrent) {
          o.next(v);
        }
      })
      .catch((e: any) => {
        e.error(e);
      });
    return () => {
      isCurrent = false;
    };
  });

export const fromPromiseFn = <T>(promFn: () => Promise<T>) =>
  new Effect((o) => {
    let isCurrent = true;
    const prom = promFn();

    prom
      .then((res: any) => {
        if (isCurrent) {
          o.next(res);
        }
      })
      .catch((e) => {
        if (isCurrent) {
          o.error(e);
        }
      });
    return () => {
      isCurrent = false;
    };
  });
