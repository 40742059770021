import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseApp = firebase.initializeApp({
  // copy and paste your firebase credential here
  apiKey: "AIzaSyAEeM9ZoT2TYYUlMy8A5nScrjGby8BoW_U",
  projectId: "draughts-n-chat",
});

export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
