import React, { useState } from "react";
import useMeasure from "react-use-measure";
import redKing from "../test/mock-game-states/red-king.json";
import "./app.css";
import "./assets/anton-regular.ttf";
import * as D from "./draughts";
import { Logo } from "./graphics";
import { PieceGraphic } from "./piece-graphic";
import { Board } from "./shared/board";
import { GameBoard } from "./shared/game-board";
import { AppHeader } from "./shared/app-header";
import { Overlay } from "./shared/overlay";
import { useGame } from "./use-game";
import { Pieces } from "./shared/pieces";
import { Link, useHistory } from "react-router-dom";

const InGameMenu = ({
  onClose,
  onClickRestart,
  onClickHome,
}: {
  onClose: () => void;
  onClickHome: () => void;
  onClickRestart: () => void;
}) => {
  return (
    <Overlay>
      <div>
        <button
          className="plain-button"
          style={{ color: "white", fontSize: "18px" }}
          onClick={() => onClickHome()}
        >
          Quit Game
        </button>
      </div>
      <div style={{ paddingBottom: "40px" }}>
        <button
          className="plain-button"
          style={{ color: "white", fontSize: "18px" }}
          onClick={() => onClickRestart()}
        >
          Restart Game
        </button>
      </div>
      <div style={{ paddingBottom: "20px" }}>
        <button
          className="button"
          style={{ color: "white" }}
          onClick={() => onClose()}
        >
          Back to Game
        </button>
      </div>
    </Overlay>
  );
};

const GameShell = ({
  children,
  onClickHome,
  onClickRestart,
}: {
  children: React.ReactNode;
  onClickHome: () => void;
  onClickRestart: () => void;
}) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {children}
      {showMenu && (
        <InGameMenu
          onClose={() => {
            setShowMenu(false);
          }}
          onClickHome={onClickHome}
          onClickRestart={() => {
            onClickRestart();
          }}
        />
      )}
    </div>
  );
};

const GameOver = ({
  game,
  onHome,
  onRestart,
}: {
  game: D.GameOver;
  onHome: () => void;
  onRestart: () => void;
}) => {
  const [ref, bounds] = useMeasure();

  return (
    <div>
      <AppHeader />
      <div
        ref={ref}
        style={{
          position: "relative",
          opacity: 0.5,
        }}
      >
        <Board size={bounds.width} />
        <Pieces
          pieces={redKing.pieces as D.Piece[]}
          size={bounds.width}
          turnStatus="opponent"
        />
      </div>
      <Overlay>
        <div style={{ fontSize: 24, fontFamily: "Anton", paddingBottom: 20 }}>
          Game Over
        </div>
        <div
          style={{
            fontFamily: "Anton",
            paddingBottom: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: "5px" }}>
            <PieceGraphic
              color={game.winner === "player1" ? "red" : "yellow"}
            />
          </div>
          <div>
            <div
              style={{
                paddingBottom: "4px",
                fontSize: 16,
              }}
            >
              Winner:
            </div>
            <div
              style={{
                fontSize: 24,
                color: game.winner === "player1" ? "red" : "yellow",
              }}
            >
              {game.winner === "player1" ? "Player 1" : "Player 2"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            className="button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => onRestart()}
          >
            Restart
          </button>
          <button
            className="button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => onHome()}
          >
            Quit
          </button>
        </div>
      </Overlay>
    </div>
  );
};

// Need a way to let this be "remote controlled" from outside for multiplayer
export const SingleGame = () => {
  const game = useGame();
  const history = useHistory();

  if (game.state.status === "over") {
    return (
      <GameOver
        game={game.state}
        onHome={() => history.push("/home")}
        onRestart={() => {
          console.log("Restart game");
        }}
      />
    );
  }

  return (
    <GameShell
      onClickHome={() => history.push("/home")}
      onClickRestart={() => {}}
    >
      <GameBoard
        gameState={game.state}
        onMove={(piece, move) => {
          game.movePiece(piece, move);
        }}
        onCompleteTurn={() => {
          game.completeTurn();
        }}
      />
    </GameShell>
  );
};
