import { juxt } from "@thi.ng/compose";
import { setIn } from "@thi.ng/paths";
import { iterator, map, range } from "@thi.ng/transducers";
// import gameState from "../test/mock-game-states/yellow-jump-king-top-left.json";

type MaybeNumber = number | null;

type Player1 = "player1";
type Player2 = "player2";

export type Player = Player1 | Player2;

interface PlayerTurn {
  status: "init" | "makingMove" | "mustComplete";
  player: Player1 | Player2;
  pieceBeingMoved?: Piece;
}

export interface Piece {
  status: "normal" | "king" | "captured";
  player: Player;
  location: number;
}

export interface GameInPlay {
  status: "inPlay";
  pieces: Piece[];
  playerTurn: PlayerTurn;
}

export interface GameOver {
  status: "over";
  winner: Player;
  endPieces: Piece[];
}

export type Game = GameInPlay | GameOver;

// prettier-ignore
export type Location = | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31;

export interface AvailableMove {
  destination: number;
  capturedPiece: Piece | null;
}

export type Jump = [Location, Location];

const defAvailableMove = (
  destination: number,
  capturedPiece: Piece | null = null
): AvailableMove => ({
  destination,
  capturedPiece,
});

const playerTurn = (player: Player): PlayerTurn => ({
  player,
  status: "init",
});

const defaultGameState: GameInPlay = {
  status: "inPlay",
  pieces: [
    ...iterator(
      map<number, Piece>((i) => ({
        player: "player1",
        location: i,
        status: "normal",
      })),
      range(0, 12)
    ),
    ...iterator(
      map<number, Piece>((i) => ({
        player: "player2",
        location: i,
        status: "normal",
      })),
      range(20, 32)
    ),
  ],
  // Who's turn is it
  playerTurn: playerTurn("player1"),
};

export const defGame = (): Game => defaultGameState;

// export const defGame = (): Game => gameState as GameInPlay;

export const newGame = (game: Game): Game => ({ ...defGame(), ...game });

//#region Location, location, location!!!

export const pieceAt = (game: GameInPlay, location: number) => {
  const found = game.pieces.find((piece) => piece.location === location);
  return found ? found : null;
};

const rowStartLocation = (row: number) => row * 4;

export const onRow = (location: number) => Math.floor(location / 4);

export const onCol = (location: number): number =>
  location - rowStartLocation(onRow(location));

/**
 * Given a starting location and a second location figure out the next location
 *
 * @param orig The starting location
 * @param adj The second location
 */
const nextLocationAfter = (orig: number, adj: number): number | null => {
  const origRow = onRow(orig);
  const adjRow = onRow(adj);
  const diffRow = adjRow - origRow;
  const row = adjRow + diffRow;
  if (row > 7) {
    return null;
  }

  const oddOffset = (origRow + 1) % 2 ? 1 : -1;

  const origCol = onCol(orig);
  const adjCol = onCol(adj);

  const diffCol = origCol - adjCol;

  const col = adjCol - diffCol + oddOffset;

  if (col > 3 || col < 0) {
    return null;
  }

  const loc = rowStartLocation(row) + col;

  return loc;
};
//#region examples
if (process.env.NODE_ENV !== "production") {
  nextLocationAfter(9, 4); /*?*/
}
//#endregion examples

/**
 * Calculate a particular adjacent location based on the row and column offset
 *
 * @param loc Location to check
 * @param rowOffset Row offset as negative or positive int
 * @param colOffset Offset col optionally
 */
const calcAdj = (loc: number, rowOffset: number, colOffset: number = 0) => {
  const origRow = onRow(loc);

  const oddOffset = origRow % 2;

  const row = origRow + rowOffset;

  if (row < 0 || row > 7) {
    return null;
  }

  const origCol = onCol(loc);

  const col = origCol + colOffset + oddOffset;

  if (col > 3 || col < 0) {
    return null;
  }

  const finalLoc = rowStartLocation(row) + col;

  return finalLoc;
};

//#region examples
if (process.env.NODE_ENV !== "production") {
  // calcAdj(17, -1) /*?*/;
  // calcAdj(17, -1, -1) /*?*/;
  // calcAdj(9, 1, -1) /*?*/;
  // calcAdj(10, 1, -1) /*?*/;

  // calcAdj(11, 1) /*?*/;
  // calcAdj(11, 1, -1) /*?*/;

  // calcAdj(13, 1) /*?*/;
  // calcAdj(13, 1, -1) /*?*/;
  calcAdj(7, 1, -1) /*?*/;
  calcAdj(7, 1) /*?*/;
}
//#endregion examples

const adjTopLeft = (loc: number): MaybeNumber => calcAdj(loc, -1);

const adjTopRight = (loc: number): MaybeNumber => calcAdj(loc, -1, -1);

const adjBottomLeft = (loc: number): MaybeNumber => calcAdj(loc, 1);

const adjBottomRight = (loc: number): MaybeNumber => calcAdj(loc, 1, -1);

const adjKing = juxt(adjTopLeft, adjTopRight, adjBottomLeft, adjBottomRight);

const adjPlayer1 = juxt(adjBottomLeft, adjBottomRight);

const adjPlayer2 = juxt(adjTopLeft, adjTopRight);

const adjacent = (piece: Piece): number[] =>
  (piece.status === "king"
    ? adjKing(piece.location)
    : piece.player === "player1"
    ? adjPlayer1(piece.location)
    : adjPlayer2(piece.location)
  ).filter((loc) => loc !== null) as number[];

//#endregion Location, location, location

//#region available jumps

export const jumpDestinationLocation = (
  origLocation: number,
  adjacentLocation: number
): number | null => {
  const origRow = onRow(origLocation);
  const origCol = onCol(origLocation);

  const adjRow = onRow(adjacentLocation);
  const adjCol = onCol(adjacentLocation);

  const offset = (adjRow % 2) - 1;

  const offsetRow = adjRow - origRow;
  const offsetCol = adjCol - origCol + 1 * offset;

  const dest = calcAdj(adjacentLocation, offsetRow, offsetCol);

  return dest;
};

/**
 * Get available jumps
 *
 * @param game Current game state
 * @param piece Piece under consideration
 */
const availableJumps = (game: GameInPlay, piece: Piece): AvailableMove[] => {
  // Get adjacent locations
  const adjLocations = adjacent(piece);

  const enemyOccupiedAdjLocations = adjLocations.filter(
    (loc) =>
      isLocationOccupied(game, loc) &&
      piece.player !== pieceAt(game, loc)?.player
  );

  const moves = enemyOccupiedAdjLocations
    .map((loc) => [nextLocationAfter(piece.location, loc), loc] as Jump)
    .filter(([dest]) => dest !== null)
    .filter(([dest]) => !isLocationOccupied(game, dest))
    .map(([dest, jumpLoc]) => defAvailableMove(dest, pieceAt(game, jumpLoc)));

  return moves;
};

//#endregion available jumps

const updatePiece = (
  pieces: Piece[],
  prevLocation: number,
  newPiece: Piece
) => {
  const pieceIndex = pieces.findIndex(
    (piece) => piece.location === prevLocation
  );
  return setIn(pieces, [pieceIndex], newPiece);
};

const isLocationOccupied = (game: GameInPlay, location: number) =>
  game.pieces.some(
    (piece) => piece.location === location && piece.status !== "captured"
  );

/**
 * All available moves, including jumps
 *
 * Factoring in piece color and if piece is a king.
 *
 * @returns all available moves
 * @todo implement kings
 */
const _availableMoves = (game: GameInPlay, piece: Piece): AvailableMove[] => {
  if (game.playerTurn.status === "mustComplete") {
    return [];
  }

  // Must use the same piece during multi move turn
  if (game.playerTurn.pieceBeingMoved) {
    if (game.playerTurn.pieceBeingMoved !== piece) {
      return [];
    }
    return availableJumps(game, piece);
  }

  const availableLocations = adjacent(piece);
  const jumpMoves = availableJumps(game, piece);

  const unoccupiedLocations = availableLocations
    .filter((loc) => loc !== null)
    .filter((loc) => !isLocationOccupied(game, loc as number))
    .map((loc) => defAvailableMove(loc, pieceAt(game, loc)));

  return [...jumpMoves, ...unoccupiedLocations];
};

const isValidMove = (game: GameInPlay, piece: Piece, move: AvailableMove) => {
  const availableMoves = _availableMoves(game, piece);

  if (
    !availableMoves.some(
      (availableMove) => availableMove.destination === move.destination
    )
  ) {
    return false;
  }
  if (!isPlayerTurn(game, piece.player)) {
    return false;
  }
  if (isLocationOccupied(game, move.destination)) {
    return false;
  }
  return true;
};

const checkKing = (piece: Piece, move: AvailableMove): Piece => {
  if (piece.status === "king") {
    return piece;
  }
  if (
    piece.player === "player1" &&
    onRow(piece.location) <= 6 &&
    onRow(move.destination) === 7
  ) {
    return {
      ...piece,
      status: "king",
    };
  }

  if (
    piece.player === "player2" &&
    onRow(piece.location) >= 1 &&
    onRow(move.destination) === 0
  ) {
    return {
      ...piece,
      status: "king",
    };
  }

  return piece;
};

const checkTurnStatus = (
  game: GameInPlay,
  piece: Piece
): PlayerTurn["status"] => {
  const moves = availableJumps(game, piece);
  return moves.length > 0 ? "makingMove" : "mustComplete";
};

/**
 * @todo need to check if a player has no more moves
 *
 * @param game The in play game
 */
const checkGameOver = (game: GameInPlay): GameInPlay | GameOver => {
  const player1Count = game.pieces.filter(
    (piece) => piece.player === "player1" && piece.status !== "captured"
  ).length;
  const player2Count = game.pieces.filter(
    (piece) => piece.player === "player2" && piece.status !== "captured"
  ).length;

  if (player1Count === 0 || player2Count === 0) {
    const winner: Player = player1Count === 0 ? "player2" : "player1";

    return {
      status: "over",
      winner,
      endPieces: game.pieces,
    };
  }

  // Check moves left here

  return game;
};

//#region api
export const capturedCount = (game: GameInPlay, player: Player) =>
  game.pieces.filter(
    (piece) =>
      piece.status === "captured" &&
      (player === "player2"
        ? piece.player === "player1"
        : piece.player === "player2")
  ).length;

export const isPlayerTurn = (game: GameInPlay, player: Player): Boolean =>
  game.playerTurn.player === player;

export const availableMoves = _availableMoves;

/**
 * Primary action in the game
 *
 * @param game Current game state
 * @param piece Piece that player wishes to move
 * @param destination Location player wishes to move piece to
 *
 * @returns New game state if valid or existing if not
 */
export const makeMove = (
  game: GameInPlay,
  piece: Piece,
  move: AvailableMove
): Game => {
  const valid = isValidMove(game, piece, move);

  if (valid) {
    const { capturedPiece } = move;

    const maybeKingedPiece = checkKing(piece, move);

    const updatedPiece: Piece = {
      ...maybeKingedPiece,
      location: move.destination as Location,
    };

    const piecesWithCurrentPieceUpdate = updatePiece(
      game.pieces,
      piece.location,
      updatedPiece
    );

    const piecesWithEnemyPieceUpdate = capturedPiece
      ? updatePiece(piecesWithCurrentPieceUpdate, capturedPiece.location, {
          ...capturedPiece,
          status: "captured",
          location: -1,
        })
      : piecesWithCurrentPieceUpdate;

    const status: PlayerTurn["status"] = capturedPiece
      ? checkTurnStatus(game, maybeKingedPiece)
      : "mustComplete";

    const gameUpdated: GameInPlay = {
      ...game,
      // TODO check if further moves are available (for a double jump)
      pieces: piecesWithEnemyPieceUpdate,
      playerTurn: {
        ...game.playerTurn,
        status,
        pieceBeingMoved: updatedPiece,
      },
    };

    // TODO shouldn't check gameover until turn actually over
    return checkGameOver(gameUpdated);
  }
  return game;
};

/**
 * Signal that move is complete
 *
 * Each turn may have a number of moves (e.g. multiple jumps). The player must
 * explicitly end their turn to allow gamplay to proceed.
 *
 * @param game Current game state
 * @returns Game state with updated status and alternate player
 */
export const completeTurn = (game: GameInPlay): Game => {
  if (
    game.playerTurn.status !== "makingMove" &&
    game.playerTurn.status !== "mustComplete"
  ) {
    return game;
  }

  return {
    ...game,
    playerTurn: {
      status: "init",
      player: game.playerTurn.player === "player1" ? "player2" : "player1",
    },
  };
};

//#endregion api
