import * as React from "react";
import { cn } from "app/utils/cn";

export interface HeadingProps extends React.HTMLProps<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const Heading = ({
  children,
  level = 1,
  ...restProps
}: HeadingProps) => {
  const Tag = `h${level}` as React.ElementType;

  return (
    <Tag {...restProps} className={cn("font-display", "uppercase", "text-2xl")}>
      {children}
    </Tag>
  );
};
