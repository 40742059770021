let res: any;

//TODO add firebase authentication
// @RezaRahmati You can use the getIdToken() method on client-side
// (e.g. firebase.auth().currentUser.getIdToken().then(token => console.log(token)))

export const ice = async () => {
  if (res) {
    return res;
  }
  return fetch("https://us-central1-draughts-n-chat.cloudfunctions.net/ice")
    .then((r) => {
      return r.json();
    })
    .then((r) => {
      res = r;
      return r;
    });
};
