import React, { useCallback, useState } from "react";
import useMeasure from "react-use-measure";
import * as D from "../draughts";
import { ThumbsUpIcon } from "../graphics";
import { PieceGraphic } from "../piece-graphic";
import { Board } from "./board";
import { PLAYER_1_COLOR, PLAYER_2_COLOR } from "./constants";
import { Pieces } from "./pieces";
import { cn } from "app/utils/cn";
import { Button } from "./buttons";
import { Flex } from "./layout";
import { View } from "./content";

type TurnStatus = "current" | "opponent";

interface PlayerStatsProps {
  player: D.Player;
  captured: number;
  isActive?: boolean;
}

const PlayerStats = ({
  player,
  captured,
  isActive = false,
}: PlayerStatsProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
        fontFamily: "Anton",
        opacity: isActive ? 1 : 0.5,
      }}
    >
      <div
        style={{
          color: player === "player1" ? PLAYER_1_COLOR : PLAYER_2_COLOR,
          opacity: 0.75,
        }}
      >
        {captured}
      </div>
      <div>
        <PieceGraphic
          color={player === "player1" ? PLAYER_1_COLOR : PLAYER_2_COLOR}
          style={{ width: "40", height: "40" }}
        />
      </div>
    </div>
  );
};

const StatsPanel = ({
  gameState,
  onCompleteTurn,
  turnStatus,
}: {
  gameState: D.GameInPlay;
  onCompleteTurn: () => void;
  turnStatus: TurnStatus;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "20px",
        fontSize: "24px",
      }}
    >
      <div>
        <PlayerStats
          player="player1"
          captured={D.capturedCount(gameState, "player1")}
          isActive={!!D.isPlayerTurn(gameState, "player1")}
        />
      </div>
      {turnStatus === "current" &&
        (gameState.playerTurn.status === "makingMove" ||
          gameState.playerTurn.status === "mustComplete") && (
          <div style={{ display: "flex" }}>
            <Button
              className={cn(
                "button",
                D.isPlayerTurn(gameState, "player1")
                  ? "bg-primary text-white"
                  : "bg-secondary text-black"
              )}
              onClick={() => {
                onCompleteTurn();
              }}
            >
              <Flex gap="2">
                <ThumbsUpIcon />
                <View>Complete turn</View>
              </Flex>
            </Button>
          </div>
        )}
      <div>
        <PlayerStats
          player="player2"
          captured={D.capturedCount(gameState, "player2")}
          isActive={!!D.isPlayerTurn(gameState, "player2")}
        />
      </div>
    </div>
  );
};

interface GameBoardProps {
  turnStatus?: TurnStatus;
  gameState: D.GameInPlay;
  onMove: (piece: any, move: any) => void;
  onCompleteTurn: () => void;
}

export const GameBoard = ({
  gameState,
  onMove,
  onCompleteTurn,
  turnStatus = "current",
}: GameBoardProps) => {
  const [ref, bounds] = useMeasure();

  const [_selectedPiece, selectPiece] = useState<D.Piece | null>(null);

  const selectedPiece = gameState.playerTurn.pieceBeingMoved
    ? gameState.playerTurn.pieceBeingMoved
    : _selectedPiece;

  const availableMoves =
    selectedPiece && D.availableMoves(gameState, selectedPiece);

  const handleCellClick = useCallback(
    (newLocation: number) => {
      if (turnStatus !== "current") {
        return;
      }
      const move = availableMoves?.find(
        ({ destination }) => destination === newLocation
      );
      onMove(selectedPiece, move);
    },
    [onMove, selectedPiece, availableMoves, turnStatus]
  );

  const handlePieceClick = useCallback(
    (location) => {
      if (turnStatus !== "current") {
        return;
      }
      const piece = gameState.pieces.find(
        (piece) => piece.location === location
      );

      piece?.player === gameState.playerTurn.player &&
        piece &&
        selectPiece(piece);
    },
    [gameState.pieces, gameState.playerTurn.player, turnStatus]
  );

  return (
    <div ref={ref}>
      <Board size={bounds.width} onCellClick={handleCellClick}>
        <Pieces
          pieces={gameState.pieces}
          size={bounds.width}
          onPieceClick={handlePieceClick}
          currentPlayer={gameState.playerTurn.player}
          selectedPieceLocation={selectedPiece?.location}
          turnStatus={turnStatus}
        />
        <div
          style={{
            position: "absolute",
            top: bounds.width + 50,
            left: 0,
            right: 0,
          }}
        >
          <StatsPanel
            gameState={gameState}
            onCompleteTurn={onCompleteTurn}
            turnStatus={turnStatus}
          />
        </div>
      </Board>
    </div>
  );
};
