import * as React from "react";
import { styled } from "../css";
import { PolymorphicComponentProps, Box } from "react-polymorphic-box";
import clsx from "clsx";
import themeRaw from "theme";
import { Spacing } from "../tw";

// export const Flex = styled(
//   "div",
//   {
//     flex: "row",
//   },
//   {
//     direction: {
//       row: {
//         flex: "row",
//       },
//       column: {
//         flex: "col",
//       },
//     },
//   }
// );

const flexUtils = {
  gap: {
    "0": "gap-0",
  },
  direction: {
    row: "flex-row",
    rowReverse: "flex-row-reverse",
    col: "flex-col",
    colReverse: "flex-col-reverse",
  },
};

// .items-start	align-items: flex-start;
// .items-end	align-items: flex-end;
// .items-center	align-items: center;
// .items-baseline	align-items: baseline;
// .items-stretch	align-items: stretch;

interface FlexOwnProps {
  gap?: Spacing;
  direction?: "row" | "rowReverse" | "col" | "colReverse";
  justify?: "start" | "end" | "center" | "around" | "evenly" | "between";
  items?: "start" | "end" | "center" | "baseline" | "stretch";
}

type FlexProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  FlexOwnProps
>;

const defaultElement = "div";

export function Flex<E extends React.ElementType>({
  gap = "0",
  direction = "row",
  justify,
  items,
  className,
  ...restProps
}: FlexProps<E>) {
  return (
    <Box
      as={defaultElement}
      className={clsx(
        "flex",
        {
          row: "flex-row",
          rowReverse: "flex-row-reverse",
          col: "flex-col",
          colReverse: "flex-col-reverse",
        }[direction],
        `space-${
          direction === "row" || direction === "rowReverse" ? "x" : "y"
        }-${gap}`,
        justify && `justify-${justify}`,
        items && `items-${items}`,
        className
      )}
      {...restProps}
    />
  );
}
