import * as React from "react";

export const Overlay = ({
  children,
  padding = 50,
}: {
  children: React.ReactNode;
  padding?: number;
}) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: "black",
          opacity: "0.5",
          zIndex: 99,
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: 150,
          left: 50,
          right: 50,
          background: "rgba(0,0,0,0.8)",
          zIndex: 100,
          borderRadius: 10,
          padding,
        }}
      >
        {children}
      </div>
    </>
  );
};
