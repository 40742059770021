import * as React from "react";
// import gameState from "test/mock-game-states/yellow-jump-king-top-left.json";
import useMeasure from "react-use-measure";
import { View } from "./content";
import { Board } from "./board";
import { cn } from "app/utils/cn";
// import { Pieces } from "./pieces";

/**
 * For the home screens
 */
export const StaticGameBoard = ({ className }: { className?: string }) => {
  const [ref, bounds] = useMeasure();

  return (
    <View ref={ref} className={cn(className)}>
      <Board size={bounds.width} onCellClick={() => {}}>
        {/* <Pieces
          pieces={gameState.pieces}
          size={bounds.width}
          turnStatus="opponent"
        /> */}
      </Board>
    </View>
  );
};
