import "firebase/firestore";
import React, { useEffect, useReducer, useState, useCallback } from "react";
import { useClipboard } from "use-clipboard-copy";
import { Effect, fromPromiseFn } from "../utils/effect";
import { db } from "../shared/firebase";
import { InPlayP2PGame } from "./shared/in-play-p2p-game";
import { useHostWebRTCConn } from "./use-host-web-rtc-conn.hook";
import { Plugins } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import { HomeScreenContainer } from "app/shared/home-screen-container";
import { Button, Flex, View } from "app/shared";
import { TextField } from "app/shared/forms/text-field";
import { Heading } from "app/shared/content/heading";
import { Center } from "app/shared/layout/center";
const { Share } = Plugins;

export const Host = ({ ice }: any) => {
  const history = useHistory();

  const [answer, setAnswer] = useState();

  const [localIceCandidatesQueue, dispatchLocalIceCandidatesQueue] = useReducer(
    (state: any[], evt: { type: "add"; data: any } | { type: "pop" }) => {
      switch (evt.type) {
        case "add":
          return [...state, evt.data];
        case "pop":
          return state.slice(0, -1);
      }
    },
    []
  );

  const [incoming, setIncoming] = useState();

  const webRTC = useHostWebRTCConn({
    answer,
    onReceive: (evt) => {
      setIncoming(evt);
    },
    onNewLocalIceCandidate: (cand: any) => {
      dispatchLocalIceCandidatesQueue({ type: "add", data: cand });
    },
    config: ice,
  });

  // const gameEvents = u

  // Setup the room in firebase
  const [roomId, setRoomId] = useState("");

  useEffect(
    () =>
      Effect.of(webRTC.offer)
        .filter((offer) => !!offer)
        .chain((offer) =>
          fromPromiseFn(async () => {
            const roomRef = await db.collection("rooms").doc();
            roomRef.set({
              offer,
            });
            return roomRef.id;
          })
        )
        .subscribe((id: any) => setRoomId(id)),
    [webRTC.offer]
  );

  // Process local ice candidates (save to db)
  // Each item is processed one by one
  useEffect(
    () =>
      Effect.of(roomId)
        .filter((roomId) => !!roomId)
        .filter(() => localIceCandidatesQueue.length > 0)
        // Get the callee candidates collection
        .map((roomId) =>
          db.collection("rooms").doc(roomId).collection("callerCandidates")
        )
        .chain((coll: any) =>
          fromPromiseFn(async () => {
            await coll.add(
              localIceCandidatesQueue[localIceCandidatesQueue.length - 1]
            );
          })
        )
        .subscribe(() => {
          dispatchLocalIceCandidatesQueue({ type: "pop" });
        }),
    [roomId, localIceCandidatesQueue, dispatchLocalIceCandidatesQueue]
  );

  // Listen for remote ice candidates
  useEffect(
    () =>
      Effect.of(roomId)
        .filter((roomId) => !!roomId)
        .map((roomId) =>
          db.collection("rooms").doc(roomId).collection("calleeCandidates")
        )
        .chain(
          (roomRef: any) =>
            new Effect((o) => {
              const sub = roomRef.onSnapshot((snapshot: any) => {
                o.next(snapshot);
              });
              return sub;
            })
        )
        .map((snapshot) => {
          return snapshot
            .docChanges()
            .filter((snapshot: any) => snapshot.type === "added")
            .map((change: any) => change.doc.data());
        })
        .subscribe(([newCandidate]: any) => {
          webRTC.addRemoteCandidate(newCandidate);
        }),
    [roomId, webRTC]
  );

  // Listen to answers from the remote guest
  useEffect(
    () =>
      Effect.of(roomId)
        .filter((roomId) => !!roomId)
        .map((roomId) => {
          return db.collection("rooms").doc(`${roomId}`);
        })
        .chain(
          (roomRef: any) =>
            new Effect((o) => {
              roomRef.onSnapshot((snapshot: any) => {
                o.next(snapshot.data());
              });
            })
        )
        .filter((data: any) => data && data.answer)
        .map((data) => data.answer)
        .subscribe((answer: any) => {
          console.log({ answer }, "host comp answer");
          setAnswer(answer);
        }),
    [roomId]
  );

  const clipboard = useClipboard({
    copiedTimeout: 1200, // timeout duration in milliseconds
  });

  const showSharing = useCallback(() => {
    Share.share({
      title: "Have a game of draughts n' chat!",
      text: "Have a game of draughts n' chat",
      url: `https://draughtsnchat.com/join-game/${roomId}`,
      dialogTitle: "Invite your friend to play",
    });
  }, [roomId]);

  if (webRTC.status !== "connected") {
    return (
      <HomeScreenContainer>
        <View className="space-y-4">
          <Heading>Creating multiplayer game...</Heading>
          {roomId && (
            <View className="space-y-4">
              <View>
                Copy and paste the following game code and send to your friend.
              </View>
              <View className="space-y-3">
                <View className="p-2 text-black bg-gray-100 border border-red-600 rounded select-all">
                  https://draughtsnchat.com/join-game/{roomId}
                </View>
                <Button variant="cta" onClick={showSharing}>
                  Share link
                </Button>
              </View>
              <View className="pt-4">
                <Button onClick={() => history.push("/home")}>
                  Back to Homescreen
                </Button>
              </View>
            </View>
          )}
        </View>
      </HomeScreenContainer>
    );
  }

  // connected
  return (
    <InPlayP2PGame
      player="player1"
      incoming={incoming}
      onCompleteTurn={() => {
        webRTC.send({
          type: "COMPLETE_TURN",
        });
      }}
      onMove={(payload) => {
        webRTC.send({
          type: "MOVE",
          payload,
        });
      }}
    />
  );
};
