import * as D from "./draughts";
import { useState } from "react";

export const useGame = () => {
  const [state, setState] = useState<D.Game>(D.defGame);

  return {
    state,
    movePiece(piece: any, move: any) {
      state.status === "inPlay" && setState(D.makeMove(state, piece, move));
    },
    completeTurn() {
      state.status === "inPlay" && setState(D.completeTurn(state));
    },
    restart() {
      setState(D.newGame(state));
    },
  };
};
