import { Button, Flex, View } from "app/shared";
import { HomeScreenContainer } from "app/shared/home-screen-container";
import "firebase/firestore";
import React from "react";
import { useHistory, Link } from "react-router-dom";

export const HomePage = () => {
  const history = useHistory();

  return (
    <HomeScreenContainer>
      <Flex direction="col" gap="4" justify="center" items="center">
        <Button variant="cta" onClick={() => history.push("/single-game")}>
          Start New Game
        </Button>
        <Button variant="cta" onClick={() => history.push("/p2p-game/host")}>
          Create multiplayer <div>game</div>
        </Button>
        <Button as={Link} variant="cta" to="/join-game">
          Join multiplayer game
        </Button>
      </Flex>
    </HomeScreenContainer>
  );
};
