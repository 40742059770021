import {
  comp,
  map,
  partition,
  push,
  range,
  transduce,
} from "@thi.ng/transducers";
import React, { memo, ReactNode, useState } from "react";

interface BoardCell {
  color: "dark" | "light";
  cellNo: number | null;
}

type BoardLayout = BoardCell[][];

/**
 * Presenting the most awkward way to generate draughts board game layout...
 */
const boardLayout: BoardLayout = transduce(
  comp(
    map<number, BoardCell>((n) => {
      const isOddCell = n % 2 === 0;

      const rowNumber = Math.floor(n / 8);
      const isOddRow = rowNumber % 2 === 0;

      const isBlackCell = isOddRow ? isOddCell : !isOddCell;

      return {
        color: isBlackCell ? "dark" : "light",
        cellNo: isBlackCell ? Math.floor(n / 2) : null,
      };
    }),
    partition(8)
  ),
  push(),
  range(64)
);

interface CellProps {
  color: BoardCell["color"];
  location: BoardCell["cellNo"];
  size: number;
  rowNo: number;
  colNo: number;
  onClick: (loc: number) => void;
}

const Cell = ({ color, location, size, rowNo, colNo, onClick }: CellProps) => {
  const [pressed, setPressed] = useState(false);

  return (
    <div
      className={color === "light" ? "light-cell" : "dark-cell"}
      style={{
        width: size,
        height: size,
        position: "absolute",
        top: rowNo * size,
        left: colNo * size,
        outline: pressed ? "3px solid black" : "none",
      }}
      onClick={() => location !== null && onClick(location)}
      onPointerDown={() => {
        setPressed(true);
      }}
      onPointerUp={() => {
        setPressed(false);
      }}
    >
      {/* {location} */}
    </div>
  );
};

export const Board = memo(
  ({
    onCellClick = (x) => x,
    size,
    opacity = 1.0,
    children,
  }: {
    size: number;
    children?: ReactNode;
    onCellClick?: (loc: number) => void;
    opacity?: number;
  }) => {
    return (
      <div
        style={{
          position: "relative",
          height: size,
          background: "red",
          opacity: opacity,
        }}
      >
        {boardLayout.map((row, rowNo) =>
          row.map((cell: BoardCell, colNo: any) => {
            return (
              <Cell
                rowNo={rowNo}
                colNo={colNo}
                color={cell.color}
                location={cell.cellNo}
                size={size / 8}
                key={`${rowNo}-${colNo}`}
                onClick={onCellClick}
              />
            );
          })
        )}
        {children}
      </div>
    );
  }
);
