import clsx from "clsx";
import React from "react";
import { Box, PolymorphicComponentProps } from "react-polymorphic-box";
import { mergeProps } from "@react-aria/utils";

export interface ButtonOwnProps {
  // Index into variant styles
  variant?: "cta" | "basic";
}

type ButtonProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  ButtonOwnProps
>;

const defaultElement = "button";

export function Button<E extends React.ElementType>({
  className,
  variant = "basic",
  ...restProps
}: ButtonProps<E>): JSX.Element {
  return (
    <Box
      as={defaultElement}
      className={clsx(
        // Focus
        "focus:outline-none",
        "focus-visible:border-gray-200",
        "focus-visible:shadow-outline",
        // Select
        "select-none",
        // Font
        "font-display",
        //Variants
        {
          cta: [
            "bg-primary",
            "px-8 py-4",
            "rounded-lg",
            "bg-primary",
            "uppercase",
            "hover:bg-secondary",
            "active:bg-primary-darker",
            "hover:text-black",
          ],
          basic: ["text-secondary", "hover:text-white", "active:text-white"],
        }[variant],
        // Animation
        "transition",
        "ease-in-out",
        "duration-150",
        // Allow for overrides
        className
      )}
      {...restProps}
    />
  );
}
