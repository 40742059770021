import clsx from "clsx";
import React from "react";
import { cn } from "app/utils/cn";

export interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {}

export function TextField({
  className,
  ...restProps
}: TextFieldProps): JSX.Element {
  return (
    <input
      className={cn(
        "shadow appearance-none bg-gray-200 focus:bg-white border border-red-600 rounded w-full py-3 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline",
        className
      )}
      {...restProps}
    />
  );
}
