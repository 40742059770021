import { HomePage } from "app/pages/home-page";
import "firebase/firestore";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { P2PGame } from "./p2p-game/p2p-game.component";
import { SingleGame } from "./single-game";
import { useAppUrlListener } from "./use-app-url-listener";

export const App = () => {
  useAppUrlListener();
  return (
    <Switch>
      <Route path="/single-game">
        <SingleGame />
      </Route>
      <Route path="/p2p-game/host">
        <P2PGame role="host" />
      </Route>
      <Route path="/join-game">
        <P2PGame role="guest" />
      </Route>
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  );
};
