import * as React from "react";
import { AppHeader } from "./app-header";
import { AppLayout } from "./app-layout";
import { View } from "./content/view";
import { Flex } from "./layout";
import { StaticGameBoard } from "./static-game-board";
import { Center } from "./layout/center";

/**
 * Container for home screens
 */
export const HomeScreenContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <>
      <AppLayout>
        <Flex gap="4" direction="col" className="h-full">
          <View>
            <AppHeader />
          </View>
          <View className="relative flex-auto">
            <Center>
              <StaticGameBoard className="w-full opacity-25" />
            </Center>
            <View className="absolute inset-0">
              <Center>
                <View className="px-4 py-8 m-4 bg-black bg-opacity-50">
                  {children}
                </View>
              </Center>
            </View>
          </View>
        </Flex>
      </AppLayout>
    </>
  );
};
