import * as React from "react";
import { Flex } from "./flex";
import { cn } from "app/utils/cn";

/**
 * Center content visually using flex box
 */
export const Center = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Flex
      justify="center"
      items="center"
      direction="col"
      className={cn("h-full", className)}
    >
      {children}
    </Flex>
  );
};
