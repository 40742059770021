import * as React from "react";
import { View } from "./content";

export interface AppLayoutProps {
  children: React.ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  return <View className="container h-screen p-4 mx-auto">{children}</View>;
};
