import * as React from "react";

interface PieceGraphicProps extends React.SVGProps<SVGSVGElement> {
  color: string;
  showKing?: boolean;
  glow?: boolean;
}

export const PieceGraphic = ({
  color,
  showKing = false,
  glow = false,
  ...restProps
}: PieceGraphicProps) => {
  return (
    <svg width={31} height={31} fill="none" viewBox="0 0 31 31" {...restProps}>
      <g filter={glow ? "url(#sofGlow)" : ""}>
        <g filter="url(#prefix__filter0_d)">
          <circle cx={13.5} cy={13.5} r={12.5} fill={color} />
          <g filter="url(#prefix__filter1_i)">
            <ellipse
              cx={13.625}
              cy={13.5}
              rx={8.375}
              ry={8.25}
              fill="#000"
              fillOpacity={0.32}
            />
          </g>
          {showKing && (
            <path
              d="M17.9 16.75h-7.8c-.165 0-.3.14-.3.313v.625c0 .171.135.312.3.312h7.8c.165 0 .3-.14.3-.313v-.625a.307.307 0 00-.3-.312zm1.2-6.25c-.497 0-.9.42-.9.938 0 .138.03.267.082.386l-1.357.848a.586.586 0 01-.829-.227L14.568 9.66c.2-.172.332-.43.332-.723C14.9 8.42 14.497 8 14 8s-.9.42-.9.938c0 .292.131.55.332.722l-1.528 2.785a.587.587 0 01-.829.227l-1.356-.848a.975.975 0 00.083-.386c0-.518-.403-.938-.9-.938S8 10.92 8 11.438c0 .517.403.937.9.937a.885.885 0 00.144-.016l1.356 3.766h7.2l1.356-3.766a.886.886 0 00.144.016c.497 0 .9-.42.9-.938 0-.517-.403-.937-.9-.937z"
              fill="#000"
            />
          )}
        </g>
      </g>
      <defs>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={31}
          height={31}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter1_i"
          x={5.25}
          y={5.25}
          width={18.75}
          height={18.5}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
          <feBlend in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter id="sofGlow" height="150%" width="150%" x="-25%" y="-25%">
          <feMorphology
            operator="dilate"
            radius="2"
            in="SourceAlpha"
            result="thicken"
          />
          <feGaussianBlur in="thicken" stdDeviation="5" result="blurred" />

          <feFlood floodColor="rgb(255,255,255, 0.1)" result="glowColor" />

          <feComposite
            in="glowColor"
            in2="blurred"
            operator="in"
            result="softGlow_colored"
          />
          <feMerge>
            <feMergeNode in="softGlow_colored" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
    </svg>
  );
};
