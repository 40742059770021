import React, { useState, useEffect } from "react";
import useMeasure from "react-use-measure";
import redKing from "../../../test/mock-game-states/red-king.json";
import "../../app.css";
import "../../assets/anton-regular.ttf";
import * as D from "../../draughts";
import { Logo } from "../../graphics";
import { PieceGraphic } from "../../piece-graphic";
import { Board } from "../../shared/board";
import { GameBoard } from "../../shared/game-board";
import { AppHeader } from "../../shared/app-header";
import { Overlay } from "../../shared/overlay";
import { useGame } from "../../use-game";
import { Pieces } from "../../shared/pieces";
import { ice } from "../twilio-api";
import { useHistory } from "react-router-dom";

ice().then((r) => {
  console.log({ r });
});

// interface InPlayP2PState {
//   status: "init" | "playing" | "waitingForOponent";
// }

// const inPlayP2PGame = (state, event) => {};

const InGameMenu = ({
  onClose,
  onClickRestart,
  onClickHome,
}: {
  onClose: () => void;
  onClickHome: () => void;
  onClickRestart: () => void;
}) => {
  return (
    <Overlay>
      <div>
        <button
          className="plain-button"
          style={{ color: "white", fontSize: "18px" }}
          onClick={() => onClickHome()}
        >
          Quit Game
        </button>
      </div>
      <div style={{ paddingBottom: "40px" }}>
        <button
          className="plain-button"
          style={{ color: "white", fontSize: "18px" }}
          onClick={() => onClickRestart()}
        >
          Restart Game
        </button>
      </div>
      <div style={{ paddingBottom: "20px" }}>
        <button
          className="button"
          style={{ color: "white" }}
          onClick={() => onClose()}
        >
          Back to Game
        </button>
      </div>
    </Overlay>
  );
};

const GameShell = ({
  children,
  onClickHome,
  onClickRestart,
}: {
  children: React.ReactNode;
  onClickHome: () => void;
  onClickRestart: () => void;
}) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Logo />
        </div>
      </div>
      {children}
      {showMenu && (
        <InGameMenu
          onClose={() => {
            setShowMenu(false);
          }}
          onClickHome={onClickHome}
          onClickRestart={() => {
            onClickRestart();
          }}
        />
      )}
    </div>
  );
};

const GameOver = ({
  game,
  onHome,
  onRestart,
}: {
  game: D.GameOver;
  onHome: () => void;
  onRestart: () => void;
}) => {
  const [ref, bounds] = useMeasure();

  return (
    <div>
      <AppHeader />
      <div
        ref={ref}
        style={{
          position: "relative",
          opacity: 0.5,
        }}
      >
        <Board size={bounds.width} />
        <Pieces
          pieces={redKing.pieces as D.Piece[]}
          size={bounds.width}
          turnStatus="opponent"
        />
      </div>
      <Overlay>
        <div style={{ fontSize: 24, fontFamily: "Anton", paddingBottom: 20 }}>
          Game Over
        </div>
        <div
          style={{
            fontFamily: "Anton",
            paddingBottom: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: "5px" }}>
            <PieceGraphic
              color={game.winner === "player1" ? "red" : "yellow"}
            />
          </div>
          <div>
            <div
              style={{
                paddingBottom: "4px",
                fontSize: 16,
              }}
            >
              Winner:
            </div>
            <div
              style={{
                fontSize: 24,
                color: game.winner === "player1" ? "red" : "yellow",
              }}
            >
              {game.winner === "player1" ? "Player 1" : "Player 2"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            className="button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => onRestart()}
          >
            Restart
          </button>
          <button
            className="button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => onHome()}
          >
            Quit
          </button>
        </div>
      </Overlay>
    </div>
  );
};

interface P2PInPlayGameProps {
  incoming: any;
  onMove: (pieceAndMove: any) => void;
  onCompleteTurn: () => void;
  player: D.Player;
}

// Need a way to let this be "remote controlled" from outside for multiplayer
export const InPlayP2PGame = ({
  incoming,
  onMove,
  onCompleteTurn,
  player,
}: P2PInPlayGameProps) => {
  const history = useHistory();
  const game = useGame();

  const [incomingQueue, setIncomingQueue] = useState<any[]>([]);

  useEffect(() => {
    setIncomingQueue((queue) => [...queue, incoming]);
  }, [incoming]);

  // "Subscribe" to incoming
  useEffect(() => {
    if (incomingQueue.length === 0) {
      return;
    }

    const item = incomingQueue[incomingQueue.length - 1];
    setIncomingQueue((queue) => queue.slice(0, -1));

    if (!(item && item.type)) {
      return;
    }

    switch (item.type) {
      case "MOVE":
        game.movePiece(item.payload.piece, item.payload.move);
        break;
      case "COMPLETE_TURN":
        game.completeTurn();
        break;
    }
  }, [incomingQueue, game]);

  if (game.state.status === "over") {
    return (
      <GameOver
        game={game.state}
        onHome={() => history.push("/home")}
        onRestart={() => {
          console.log("Restart game");
        }}
      />
    );
  }

  const turnStatus =
    game.state.playerTurn.player === player ? "current" : "opponent";

  return (
    <GameShell
      onClickHome={() => history.push("/home")}
      onClickRestart={() => {}}
    >
      <GameBoard
        turnStatus={turnStatus}
        gameState={game.state}
        onMove={(piece, move) => {
          game.movePiece(piece, move);
          onMove({ piece, move });
        }}
        onCompleteTurn={() => {
          game.completeTurn();
          onCompleteTurn();
        }}
      />
    </GameShell>
  );
};
