import "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { db } from "../shared/firebase";
import { Effect, fromPromiseFn } from "../utils/effect";
import { InPlayP2PGame } from "./shared/in-play-p2p-game";
import { useGuestWebRTCConn } from "./use-guest-web-rtc-conn.hook";
import { HomeScreenContainer } from "app/shared/home-screen-container";
import { Heading } from "app/shared/content/heading";
import { View, Button, Flex } from "app/shared";
import { TextField } from "app/shared/forms/text-field";
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useParams,
  Link,
} from "react-router-dom";

const PreGame = () => {
  let { url } = useRouteMatch();
  const history = useHistory();

  const [gameCode, setGameCode] = useState("");

  return (
    <HomeScreenContainer>
      <View className="space-y-4">
        <View className="space-y-4">
          <View>Enter the connection code sent by your friend here:</View>
          <View>
            <TextField
              onChange={(e: any) => setGameCode(e.target.value)}
              value={gameCode}
            ></TextField>
          </View>
        </View>
        <Flex justify="between" items="center">
          <Button
            variant="cta"
            onClick={() =>
              gameCode &&
              history.push(`${url.split("/").concat(gameCode).join("/")}`)
            }
          >
            Join Game ({url.split("/").concat([gameCode]).join("/")})
          </Button>
          <Button to="/" as={Link}>
            Return to Homescreen
          </Button>
        </Flex>
      </View>
    </HomeScreenContainer>
  );
};

const Main = ({ ice }: { ice: any }) => {
  const { gameCode } = useParams();

  const [offer, setOffer] = useState(null);

  useEffect(
    () =>
      fromPromiseFn(async () => {
        const roomRef = db.collection("rooms").doc(`${gameCode}`);
        const roomSnapshot = await roomRef.get();
        console.log(roomSnapshot.exists, "exists");
        if (roomSnapshot.exists) {
          const data: any = roomSnapshot.data();
          if (data.offer) {
            return data.offer;
          }
        }
        return null;
      })
        .filter((v) => !!v)
        .subscribe((offer: any) => setOffer(offer)),
    [gameCode]
  );

  const onIceCandidate = useCallback(
    (cand: any) => {
      if (!gameCode) {
        return;
      }
      const roomRef = db.collection("rooms").doc(`${gameCode}`);
      const calleeCandidatesCollection = roomRef.collection("calleeCandidates");
      console.log("adding cand", cand);
      cand && calleeCandidatesCollection.add(cand);
    },
    [gameCode]
  );

  const [incoming, setIncoming] = useState();

  const webRTC: any = useGuestWebRTCConn({
    onReceive: (data) => {
      setIncoming(data);
    },
    onIceCandidate,
    offer,
    config: ice,
  });

  useEffect(
    () =>
      Effect.of({ answer: webRTC.answer, roomId: gameCode })
        .filter((deps) => !!deps.answer && !!deps.roomId)
        .chain(() =>
          fromPromiseFn(async () => {
            const roomRef = db.collection("rooms").doc(`${gameCode}`);
            const roomSnapshot = await roomRef.get();
            if (roomSnapshot.exists) {
              await roomRef.update(webRTC.answer!);
            }
          })
        )
        .subscribe(),
    [webRTC.answer, gameCode]
  );

  // Listen for remote ice candidates
  useEffect(
    () =>
      Effect.of(gameCode)
        .filter((gameCode) => !!gameCode)
        .map((gameCode) =>
          db.collection("rooms").doc(gameCode).collection("callerCandidates")
        )
        .chain(
          (roomRef: any) =>
            new Effect((o) => {
              const sub = roomRef.onSnapshot((snapshot: any) => {
                o.next(snapshot);
              });
              return sub;
            })
        )
        .map((snapshot) => {
          return snapshot
            .docChanges()
            .filter((snapshot: any) => snapshot.type === "added")
            .map((change: any) => change.doc.data());
        })
        .subscribe(([newCandidate]: any) => {
          webRTC.addRemoteCandidate(newCandidate);
        }),
    [gameCode, webRTC]
  );

  if (webRTC.status !== "connected") {
    return (
      <HomeScreenContainer>
        <View className="space-y-4">
          <Heading>Connecting</Heading>
          <View>
            <Button as={Link} to="/">
              Return to Homescreen
            </Button>
          </View>
        </View>
      </HomeScreenContainer>
    );
  }

  // connected
  return (
    <InPlayP2PGame
      player="player2"
      incoming={incoming}
      onCompleteTurn={() => {
        webRTC.send({
          type: "COMPLETE_TURN",
        });
      }}
      onMove={(payload) => {
        webRTC.send({
          type: "MOVE",
          payload,
        });
      }}
    />
  );
};

export const Guest = ({ ice }: { ice: any }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PreGame />
      </Route>
      <Route path={`${path}/:gameCode`}>
        <Main ice={ice} />
      </Route>
    </Switch>
  );
};
