import React, { useState, useEffect } from "react";
import { Guest } from "./guest.component";
import { Host } from "./host.component";
import { ice } from "./twilio-api";
import { auth } from "../shared/firebase";

interface P2PGameProps {
  role: "host" | "guest";
}

export const P2PGame = ({ role }: P2PGameProps) => {
  const [iceSettings, setIceSettings] = useState();

  useEffect(() => {
    let current = true;
    ice().then((v) => {
      if (current) {
        setIceSettings(v.iceServers);
      }
    });
    return () => {
      current = false;
    };
  });

  const [user, setUser] = useState<any>();

  useEffect(() => {
    auth.signInAnonymously();
  }, []);

  useEffect(
    () =>
      auth.onAuthStateChanged(function (user) {
        if (user) {
          setUser(user);
        }
      }),
    []
  );

  // TODO do proper loading here
  if (!(iceSettings && user)) {
    return null;
  }

  if (role === "host") {
    return <Host ice={iceSettings} />;
  }
  return <Guest ice={iceSettings} />;
};
