import * as D from "../draughts";

import React, { useCallback, useState, memo } from "react";
import { PieceGraphic } from "../piece-graphic";
import {
  PLAYER_1_SELECTED_COLOR,
  PLAYER_1_COLOR,
  PLAYER_2_SELECTED_COLOR,
  PLAYER_2_COLOR,
} from "./constants";

interface PieceProps {
  onClick: (piece: D.Piece["location"]) => void;
  state: "unselected" | "selected";
  player: D.Piece["player"];
  status: D.Piece["status"];
  location: D.Piece["location"];
  size: number;
  isCurrent: boolean;
}

const _Piece = ({
  onClick,
  player,
  status,
  state,
  location,
  size,
  isCurrent,
}: PieceProps) => {
  const col = D.onCol(location);
  const row = D.onRow(location);

  const svgStyle = {
    width: "90%",
    height: "90%",
  };

  const handleClick = useCallback(() => {
    onClick(location);
  }, [location, onClick]);

  const oddRowOffset = row % 2;

  const [pressed, setPressed] = useState(false);

  const active = isCurrent && (pressed || state === "selected");

  // Don't show if the piece has been captured
  if (status === "captured") {
    return null;
  }

  const adjustedSize = active ? size : size * 0.9;

  return (
    <div
      style={{
        position: "absolute",
        width: adjustedSize,
        height: adjustedSize,
        left: size * (col * 2) + size / 10 + size * oddRowOffset,
        top: size * row + size / 10,
      }}
    >
      {player === "player1" ? (
        <PieceGraphic
          style={svgStyle}
          color={active ? PLAYER_1_SELECTED_COLOR : PLAYER_1_COLOR}
          onClick={handleClick}
          onPointerDown={() => isCurrent && setPressed(true)}
          onPointerUp={() => isCurrent && setPressed(false)}
          glow={state === "selected"}
          showKing={status === "king"}
        />
      ) : (
        <PieceGraphic
          color={active ? PLAYER_2_SELECTED_COLOR : PLAYER_2_COLOR}
          style={svgStyle}
          onClick={handleClick}
          onPointerDown={() => isCurrent && setPressed(true)}
          onPointerUp={() => isCurrent && setPressed(false)}
          glow={state === "selected"}
          showKing={status === "king"}
        />
      )}
    </div>
  );
};

const Piece = memo(_Piece);

interface PiecesProps {
  pieces: D.Piece[];
  size: number;
  onPieceClick?: (piece: D.Piece["location"]) => void;
  currentPlayer?: D.Player;
  selectedPieceLocation?: number;
  turnStatus: "current" | "opponent";
}

export const Pieces = ({
  pieces,
  size,
  selectedPieceLocation,
  currentPlayer = "player1",
  onPieceClick = (x) => x,
  turnStatus,
}: PiecesProps) => {
  return (
    <>
      {pieces.map((piece, idx) => {
        return (
          <Piece
            key={idx}
            size={size / 8}
            location={piece.location}
            player={piece.player}
            status={piece.status}
            state={
              selectedPieceLocation === piece.location
                ? "selected"
                : "unselected"
            }
            isCurrent={
              piece.player === currentPlayer && turnStatus === "current"
            }
            onClick={onPieceClick}
          ></Piece>
        );
      })}
    </>
  );
};
