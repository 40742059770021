import * as React from "react";
import { Logo } from "../graphics";

interface AppHeaderProps {
  variant?: "menu" | "noMenu";
}

export const AppHeader = ({ variant = "noMenu" }: AppHeaderProps) => {
  return (
    <div className="flex justify-between">
      <div>
        <Logo />
      </div>
      {
        {
          menu: <div className="bg-red-700">Menu</div>,
          noMenu: null,
        }[variant]
      }
    </div>
  );
};
