import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useRef,
} from "react";
import { fromPromiseFn } from "../utils/effect";

interface Room {
  status: "pending" | "connected" | "closing" | "closed";
  roomId?: string;
  outgoing?: any;
  offer?: any;
  answer?: any;
  send: (data: any) => void;
  close: () => void;
  addRemoteCandidate: (cand: any) => void;
}

interface RoomProps {
  onReceive: (data: any) => void;
  answer: any;
  onNewLocalIceCandidate: any;
  config: any;
}

/**
 * Setup a webrtc peer connection as the host
 */
export const useHostWebRTCConn = ({
  onReceive,
  onNewLocalIceCandidate,
  answer,
  config,
}: RoomProps): Room => {
  const [status, dispatch] = useReducer(
    (status: "pending" | "connected" | "closed", evt: "CONNECT" | "CLOSE") => {
      switch (status) {
        case "pending":
          switch (evt) {
            case "CONNECT":
              return "connected";
            default:
              return status;
          }
        case "connected":
          switch (evt) {
            case "CLOSE":
              return "closed";
            default:
              return status;
          }
        default:
          return status;
      }
    },
    "pending"
  );

  // Setup rtc stuff
  const peerConnection = useMemo(
    () => new RTCPeerConnection({ iceServers: config }),
    [config]
  );

  const sendChannel = useMemo(() => peerConnection.createDataChannel("game"), [
    peerConnection,
  ]);

  const [offer, setOffer] = useState<any>(null);

  useEffect(
    () =>
      fromPromiseFn(async () => {
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        return {
          type: offer.type,
          sdp: offer.sdp,
        };
      }).subscribe((offer) => setOffer(offer)),
    [peerConnection]
  );

  // Local ice candidates
  useEffect(() => {
    const handle = (event: RTCPeerConnectionIceEvent) => {
      if (!event.candidate) {
        console.log("Got final candidate!");
        return;
      }
      onNewLocalIceCandidate(event.candidate.toJSON());
    };

    peerConnection.addEventListener("icecandidate", handle);
    return () => {
      peerConnection.removeEventListener("icecandidate", handle);
    };
  }, [peerConnection, onNewLocalIceCandidate]);

  const [hasAnswered, setHasAnswered] = useState(false);

  console.log({ answer }, "answer");

  useEffect(() => {
    if (!answer) {
      return;
    }
    if (hasAnswered) {
      return;
    }
    setHasAnswered(true);
    const rtcSessionDescription = new RTCSessionDescription(answer);
    peerConnection.setRemoteDescription(rtcSessionDescription);
  }, [answer, peerConnection, hasAnswered]);

  useEffect(() => {
    const handleStateChange = () => {
      console.log(sendChannel.readyState, "state change");
      if (sendChannel.readyState === "open") {
        dispatch("CONNECT");
      } else {
        dispatch("CLOSE");
      }
    };

    const handleMsgReceive = (event: MessageEvent) => {
      const msg = event.data;
      onReceive(JSON.parse(msg));
    };

    sendChannel.addEventListener("open", handleStateChange);
    sendChannel.addEventListener("close", handleStateChange);
    sendChannel.addEventListener("message", handleMsgReceive);

    return () => {
      sendChannel.removeEventListener("open", handleStateChange);
      sendChannel.removeEventListener("close", handleStateChange);
      sendChannel.removeEventListener("message", handleMsgReceive);
    };
  }, [sendChannel, onReceive]);

  const send = useCallback(
    (msg) => {
      sendChannel && sendChannel.send(JSON.stringify(msg));
    },
    [sendChannel]
  );

  const cands = useRef<any[]>([]);

  useEffect(() => {
    if (hasAnswered) {
      cands.current.forEach((cand: any) => {
        console.log("Add cand", cand);
        cand && peerConnection.addIceCandidate(new RTCIceCandidate(cand));
      });
      cands.current = [];
    }
  }, [hasAnswered, peerConnection]);

  return {
    status,
    offer,
    send,
    close: () => {
      dispatch("CLOSE");
    },
    addRemoteCandidate: (cand) => {
      if (hasAnswered) {
        peerConnection.addIceCandidate(new RTCIceCandidate(cand));
      } else {
        cands.current = [...cands.current, cand];
      }
      console.log(cands.current, "cands");
    },
  };
};
