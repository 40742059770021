import { AndroidFullScreen } from "@ionic-native/android-full-screen";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { isPlatform } from "@ionic/react";
import "focus-visible";
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app/app";
import * as serviceWorker from "./serviceWorker";

if (isPlatform("android") && isPlatform("capacitor")) {
  ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  AndroidFullScreen.immersiveMode();
}

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }: any) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const WrappedApp = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

ReactDOM.render(<WrappedApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
